<template>
    <div class="view-trip-list" v-if="translates && translates[langUrl]">
        <nav v-if="isDesktop && translates.navigation" class="navigation">
            <div class="link" @click="mainPage">{{ translates.navigation.button_route_main[lang] }} ></div>
            <div class="link" @click="myTrips">{{ translates.navigation.button_route_trips[lang] }}</div>
        </nav>
        <div class="view-trip-list__header">
            <h1 class="view-trip-list__title">{{ translates[langUrl].pageTitle[lang] }}</h1>
            
            <template v-if="isDesktop">
                <div class="view-trip-list__header__menu">
                    <div
                        class="view-trip-list__header__menu__item"
                        :class="selectedMenu === 'all' ? 'active' : ''"
                        @click="setMenu('all')"
                    >{{ translates[langUrl].trips_all[lang] }}</div>
                    <div
                        class="view-trip-list__header__menu__item"
                        :class="selectedMenu === 'blogger' ? 'active' : ''"
                        @click="setMenu('blogger')"
                    >{{ translates[langUrl].trips_blogger[lang] }}</div>
                </div>
            </template>
            
            <BaseButton class="view-trip-list__header__button" @click="telegramUserLink">{{ translates[langUrl].button_botLink[lang] }}</BaseButton>
        </div>
        <template v-if="!isDesktop">
            <div class="view-trip-list__header__menu">
                <div
                    class="view-trip-list__header__menu__item"
                    :class="selectedMenu === 'all' ? 'active' : ''"
                    @click="setMenu('all')"
                >{{ translates[langUrl].trips_all[lang] }}</div>
                <div
                    class="view-trip-list__header__menu__item"
                    :class="selectedMenu === 'blogger' ? 'active' : ''"
                    @click="setMenu('blogger')"
                >{{ translates[langUrl].trips_blogger[lang] }}</div>
            </div>
        </template>
        <div class="view-trip-list__content">
            <div v-if="isDesktop">
                <div class="view-trip-list__preview">
                    <template v-for="(trip, index) in filteredTrips">
                        <DesktopPreviewCard
                            :key="index"
                            :trip="trip"
                            :buttons="['edit', 'copy', 'moderated']"
                            show-type
                            @copy="copy"
                            @setStatus="setStatus" />
                    </template>
                </div>
            </div>
            <div v-else>
                <div class="view-trip-list__preview">
                    <template v-for="(trip, index) in filteredTrips">
                        <PreviewCard
                            :key="index"
                            :trip="trip"
                            :buttons="['edit', 'copy', 'moderated']"
                            show-type
                            @copy="copy"
                            @setStatus="setStatus" />
                    </template>
                </div>
            </div>
        </div>
        <!-- <v-pagination
            v-if="tripsCount > 10"
            v-model="page"
            :length="pageCount()"
            circle
            @input="getTrips"
        /> -->
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import PreviewCard from '../../components/common/trip/PreviewCard.vue';
    import BaseButton from '../../components/common/BaseButton.vue';
    import DesktopPreviewCard from '../../components/desktop/trip/PreviewCard.vue'
    import DesktopFilters from '../../components/desktop/trip/Filters.vue'
    import Filters from '../../components/mobile/trip/Filters.vue'
    import { TRIP_TYPE, TRIP_STATUS } from '@/vars';

    export default {
        name: 'Trips',
        metaInfo() {
            return {
                title: this.translates[this.langUrl]? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            PreviewCard,
            DesktopPreviewCard,
            DesktopFilters,
            Filters,
            BaseButton
        },
        data: () => ({
            isDesktop: false,
            selectedMenu: 'all',
            filter: {},
            page: 1,
            langUrl: '/user/trips'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trips: state => state.entities,
                tripsCount: state => state.entitiesCount,
            }),
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            
            filteredTrips() {
                switch(this.selectedMenu) {
                    case 'all':
                        return this.trips.filter(item => item.type !== TRIP_TYPE.TOUR_BLOGGER);
                    break;
                    case 'blogger':
                        return this.trips.filter(item => item.type === TRIP_TYPE.TOUR_BLOGGER);
                    break;
                }
                return [];
            },
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            store.commit('trips/CLEAR_ENTITIES');
            await this.getTrips();
            this.$root.$emit('preloaderHide');
        },
        // async updated() {
        //     await this.getTrips();
        // },
        methods: {
            pageCount() {
                return Math.ceil(this.tripsCount / 10)
            },
            async getTrips() {
                if(!this.user._id) {
                    await store.dispatch('auth/fetch');
                }
                await store.dispatch('trips/fetch', { 
                    filter: {
                        ...{ user: this.user._id } 
                    }, 
                    page: this.page,
                    itemsPerPage: 10000 
                });
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async myTrips () {
                await this.$router.push({ name: 'user-trips' });
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
            async applyFilters({ filter, sorting }) {
                this.filter = filter;
                this.sorting = sorting;
                await this.getTrips();
            },
            async copy({ id }) {
                await store.dispatch('trips/copy', { id });
                await this.getTrips();
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
            telegramUserLink() {
                window.open(`https://t.me/${process.env.VUE_APP_TELEGRAM_USER_BOT}`, '_blank');
            },
            setMenu(menu) {
                this.selectedMenu = menu;
            }
        }
    }
</script>

<style lang="scss">
.view-trip-list {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    @media all and (min-width: 768px) {
        padding: 0 32px;
    }
    @media all and (min-width:1464px) {
        padding: 0;
    }
    &__menu{
        margin: 13px 24px 0;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #44414D;
        @media all and (min-width:768px) {
            margin: 13px 0 0;
        }
    }
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: scroll;
        padding: 32px 24px 12px;

        &::-webkit-scrollbar {
            display: none;
        }
        @media all and (max-width: 768px) {
            width: 100%;
            flex-direction: column;
        }
        &__button {
            @media all and (max-width: 768px) {
                width: 100%;
                margin-top: 20px;
            }
        }
        &__menu {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            @media all and (max-width: 768px) {
                padding: 10px 0 20px;
            }

            &__item {
                padding-left: 32px;
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                color: #A1A4B1;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:first-of-type {
                    padding-left: 0;
                }
                @media all and (max-width: 768px) {
                    font-size: 16px;
                    padding-left: 20px;
                    &:first-of-type {
                        padding-left: 20px;
                    }
                    &:last-of-type {
                        padding-right: 20px;
                    }
                }
                &.active {
                    color: #273155;
                    font-size: 28px;
                    line-height: 140%;
                    @media all and (max-width: 768px) {
                        font-size: 24px;
                    }
                }
                
                @media all and (max-width: 768px) {
                    position: relative;
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 150%;
                    text-align: center;
                    letter-spacing: -0.02em;
                }
            }
        }
    }
    &__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #3A4256;
        @media all and (min-width:768px) {
        font-weight: bold;
        font-size: 38px;
        line-height: 46px;
        color: #273155;
        margin: 40px 0 36px;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
    }
    &__preview {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media all and (min-width:768px) {
            flex-direction: column;
            justify-content: space-between;
            // padding: 0 32px;
        }
    }
}

</style>
